<template>

  <div class="ellipse-bg-bottom" >

    <div class="ellipse-bg">
      <div class="pt-12">
        <Settings class="box"></Settings>
      </div>
      <div class="box mt-8">
        <ChangePassword></ChangePassword>
      </div>
    </div>
  </div>



</template>

<script>
import ChangePassword from '@/views/admin/profile/ChangePassword.vue'
import Settings from '@/components/Profile/Settings.vue'
export default {
  name: 'ManageProfile',
  components: {ChangePassword, Settings}
}
</script>
<style scoped>

.box{
  border: 1px solid var(--v-primary-base);
  border-radius: 6px;
  margin-left: 50px;
  margin-right: 50px;
  /* margin-top:50px; */
  padding: 40px;
}
.ellipse-bg {
  background-image: radial-gradient(farthest-corner at 100% 0%, rgba(3, 147, 168,0.40) 0%, transparent 45% );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: right;
  background-position-y: center;
  background-size: 100vw 100vh;
  min-height: 100vh;
}

.ellipse-bg-bottom {
  background-image: radial-gradient(farthest-corner at 0% 100%, rgba(3, 147, 168,0.40) 0%, transparent 45% );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: left;
  background-position-y: center;
  background-size: 100vw 100vh;
  min-height: 100vh;
}


</style>