import ApiIntegrface from "../api"
import store from '@/store'
export function getUserSetting(callback){
    const api = new ApiIntegrface()
    const url = '/api/v1/users/' + store.state.UserId + '/settings'
    api.get(url, null, callback)
}
export function updateUserSetting(settingId, payload,callback, successMessage){
    const api = new ApiIntegrface()
    const url = '/api/v1/users/' + store.state.UserId + '/settings/' + settingId
    api.put(url, payload, callback,null, successMessage)
}