<template>
  <div class="overflow-auto">
    <div class="text-h4 mb-10">PREFERENCES</div>

    <div class="text-h5 mb-3 mt-3">SELECT DASHBOAD THEME</div>
    <div class="d-flex flex-row">
      <div class="flex-grow-1">
        <!--        <v-radio-group class="mt-0"  v-model="this.$store.state.darkMode" mandatory >-->
        <!--          <v-radio :value="false" >-->
        <!--            <template v-slot:label>-->
        <!--              <div >Light</div>-->
        <!--            </template>-->
        <!--          </v-radio>-->
        <!--          <v-radio :value="true">-->
        <!--            <template v-slot:label>-->
        <!--              <div >Dark</div>-->
        <!--            </template>-->
        <!--          </v-radio>-->
        <!--        </v-radio-group>-->
        <ThemeButton/>

        <div class="text-h5 mb-3 mt-6">LOGOUT MetalNX</div>
        <CBtn color="primary" class="px-8 mt-5 mb-2" prepen @click="logout()">
          Logout
        </CBtn>

        <div class="text-h5 mb-3 mt-6">LAST LOGIN :</div>
        <p>{{ savedTime }}</p>


      </div>


      <div class="mx-16 px-16 ">
        <img src="@/assets/img/setting.png"/>
      </div>
    </div>
  </div>
</template>
<script>
import CBtn from '@/components/Vuetify/CBtn.vue'

import {getUserSetting} from '@/api/user/userSetting.js'

import ThemeButton from "@/components/ThemeButton.vue";


export default {
  name: 'Settings',
  components: {CBtn, ThemeButton},
  data: () => ({
    savedTime: null,
    uiSettings: {
      key: 'uiSetting',
      value: {
        darkMode: false
      },
      id: null
    }
  }),
  created() {
    const _this = this
    getUserSetting((res) => {
      _this.uiSettings = res.find(item => item.key === 'uiSetting')
    })
  },
  mounted() {
    this.retrieveSavedTime(); // Automatically retrieve saved time on component mount
  },
  methods: {
    handleDarkMode() {
      const currentDarkModeState = this.$store.state.darkMode; // Get the current state
      this.$store.commit('darkModeState', !currentDarkModeState);

    }
    ,
    logout() {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
    changeMode() {
      this.$store.commit('darkModeState', false)
    },
    retrieveSavedTime() {
      // Retrieve the time from local storage
      const time = localStorage.getItem('savedTime');
      this.savedTime = time ? new Date(time).toLocaleString() : 'No time saved';
    }
  }
}


</script>
<style scoped>


</style>